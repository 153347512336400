import { httpClient } from './axiosClients'
import { commaSerializer } from './paramSerializers'

export function getCosts (payload) {
  const { url = '/api/costs', params } = payload
  return httpClient.get(url, {
    params,
    paramsSerializer: commaSerializer
  })
}

export function createCost (payload) {
  return httpClient.post('api/costs', payload)
}

export function updateCost (costId, payload) {
  return httpClient.patch(`/api/costs/${costId}`, payload)
}

export function deleteCost (costId) {
  return httpClient.delete(`/api/costs/${costId}`)
}

export function createCreditNote (costId, payload) {
  return httpClient.post(`/api/costs/${costId}/credit-note`, payload)
}

export function getPropertyCommissionFeeProduct (propertyId) {
  return httpClient.get(`/api/properties/${propertyId}/commission-fee-product`)
}
