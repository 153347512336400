var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.progress)?_c('ul',{staticClass:"tw-text-right tw-mt-2"},[_c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.progress.validations_done)?_c('li',{key:"validations_done",staticClass:"tw-my-0.5 tw-text-xs tw-text-primary"},[_vm._v(" Gegevens gevalideerd "),_c('i',{staticClass:"fas fa-check-circle"})]):_vm._e(),(_vm.progress.contacts_synced)?_c('li',{key:"contacts_synced",staticClass:"tw-my-0.5 tw-text-xs tw-text-primary"},[_vm._v(" Facturatiecontacten gesynchroniseerd met BoCount "),_c('i',{staticClass:"fas fa-check-circle"})]):_vm._e(),(_vm.progress.invoices_created)?_c('li',{key:"invoices_created",staticClass:"tw-my-0.5 tw-text-xs tw-text-primary"},[_vm._v(" "+_vm._s(_vm.documentType === 1 ? 'Factuurgegevens aangemaakt' : 'Creditnotagegevens aangemaakt')+" "),_c('i',{staticClass:"fas fa-check-circle"})]):_vm._e(),(_vm.progress.invoices_synced_ids)?_c('li',{key:"invoices_synced_ids",class:[
        'tw-my-0.5 tw-text-xs',
        { 'tw-text-primary' : _vm.progress.invoices_synced_ids.length === _vm.progress.invoices_created.length }
      ]},[_vm._v(" "+_vm._s(_vm.documentType === 1 ? 'Facturen' : "Creditnota's")+" geboekt in BoCount: "+_vm._s(_vm.progress.invoices_synced_ids.length)+" / "+_vm._s(_vm.progress.invoices_created.length)+" "),_c('i',{class:[
          'fas',
          _vm.progress.invoices_synced_ids.length === _vm.progress.invoices_created.length || !_vm.invoicing
            ? 'fa-check-circle'
            : 'fa-spinner-third fa-spin'
        ]})]):_vm._e(),(_vm.progress.invoices_synced_ids || !_vm.invoicing)?_c('li',{key:"invoices_with_pdf_ids",class:[
        'tw-my-0.5 tw-text-xs',
        { 'tw-text-primary' : _vm.progress.invoices_with_pdf_ids.length === _vm.progress.invoices_created.length }
      ]},[_vm._v(" "+_vm._s(_vm.documentType === 1 ? 'Facturen' : "Creditnota's")+" als pdf opgeslagen in IAM: "+_vm._s(_vm.progress.invoices_with_pdf_ids.length)+" / "+_vm._s(_vm.progress.invoices_created.length)+" "),_c('i',{class:[
          'fas',
          _vm.progress.invoices_with_pdf_ids.length === _vm.progress.invoices_created.length || !_vm.invoicing
            ? 'fa-check-circle'
            : 'fa-spinner-third fa-spin'
        ]})]):_vm._e(),(_vm.progress.invoices_failed_ids && _vm.progress.invoices_failed_ids.length)?_c('li',{key:"invoices_failed_ids",staticClass:"tw-my-0.5 tw-text-xs tw-text-error"},[_vm._v(" Mislukte "+_vm._s(_vm.documentType === 1 ? 'facturen' : "creditnota's")+": "+_vm._s(_vm.progress.invoices_failed_ids.length)+" / "+_vm._s(_vm.progress.invoices_created.length)+" "),_c('i',{staticClass:"fas fa-times-circle"})]):_vm._e(),(_vm.progress.error_code)?_c('li',{key:"error_code",staticClass:"tw-my-0.5 tw-text-xs tw-text-error"},[_vm._v(" "+_vm._s(_vm.invoicingErrors[_vm.progress.error_code])+" "),_c('i',{staticClass:"fas fa-times-circle"})]):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }