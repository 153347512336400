<template>
  <ul v-if="progress" class="tw-text-right tw-mt-2">
    <transition-group name="fade" mode="out-in">
      <li
        v-if="progress.validations_done"
        key="validations_done"
        class="tw-my-0.5 tw-text-xs tw-text-primary"
      >
        Gegevens gevalideerd
        <i class="fas fa-check-circle" />
      </li>
      <li
        v-if="progress.contacts_synced"
        key="contacts_synced"
        class="tw-my-0.5 tw-text-xs tw-text-primary"
      >
        Facturatiecontacten gesynchroniseerd met BoCount
        <i class="fas fa-check-circle" />
      </li>

      <li
        v-if="progress.invoices_created"
        key="invoices_created"
        class="tw-my-0.5 tw-text-xs tw-text-primary"
      >
        {{ documentType === 1 ? 'Factuurgegevens aangemaakt' : 'Creditnotagegevens aangemaakt' }}
        <i class="fas fa-check-circle" />
      </li>

      <li
        v-if="progress.invoices_synced_ids"
        key="invoices_synced_ids"
        :class="[
          'tw-my-0.5 tw-text-xs',
          { 'tw-text-primary' : progress.invoices_synced_ids.length === progress.invoices_created.length }
        ]"
      >
        {{ documentType === 1 ? 'Facturen' : "Creditnota's" }} geboekt in BoCount:
        {{ progress.invoices_synced_ids.length }}
        /
        {{ progress.invoices_created.length }}
        <i
          :class="[
            'fas',
            progress.invoices_synced_ids.length === progress.invoices_created.length || !invoicing
              ? 'fa-check-circle'
              : 'fa-spinner-third fa-spin'
          ]"
        />
      </li>

      <li
        v-if="progress.invoices_synced_ids || !invoicing"
        key="invoices_with_pdf_ids"
        :class="[
          'tw-my-0.5 tw-text-xs',
          { 'tw-text-primary' : progress.invoices_with_pdf_ids.length === progress.invoices_created.length }
        ]"
      >
        {{ documentType === 1 ? 'Facturen' : "Creditnota's" }} als pdf opgeslagen in IAM:
        {{ progress.invoices_with_pdf_ids.length }}
        /
        {{ progress.invoices_created.length }}
        <i
          :class="[
            'fas',
            progress.invoices_with_pdf_ids.length === progress.invoices_created.length || !invoicing
              ? 'fa-check-circle'
              : 'fa-spinner-third fa-spin'
          ]"
        />
      </li>

      <li
        v-if="progress.invoices_failed_ids && progress.invoices_failed_ids.length"
        key="invoices_failed_ids"
        class="tw-my-0.5 tw-text-xs tw-text-error"
      >
        Mislukte {{ documentType === 1 ? 'facturen' : "creditnota's" }}:
        {{ progress.invoices_failed_ids.length }}
        /
        {{ progress.invoices_created.length }}
        <i class="fas fa-times-circle" />
      </li>

      <li
        v-if="progress.error_code"
        key="error_code"
        class="tw-my-0.5 tw-text-xs tw-text-error"
      >
        {{ invoicingErrors[progress.error_code] }}
        <i class="fas fa-times-circle" />
      </li>
    </transition-group>
  </ul>
</template>

<script>
export default {
  name: 'EntityInvoiceProcess',
  props: {
    invoicing: {
      type: Boolean
    },
    documentType: {
      type: Number
    },
    progress: {
      type: Object
    }
  },
  computed: {
    invoicingErrors () {
      return {
        invoicing_share: 'De verdeelsleutel tussen de facturatiecontacten is niet gelijk aan 100%.',
        invoicing_contact: 'Eén van de facturatiecontacten is niet gekoppeld als eigenaar.',
        incorrect_vat_number: 'Eén van de facturatiecontacten heeft een fout btw-nummer.',
        uninvoiceable_cost: `Kost is niet ${this.documentType === 1 ? 'factureerbaar' : 'crediteerbaar'}.`,
        invoiced_cost: `Kost is al ${this.documentType === 1 ? 'gefactureerd' : 'gecrediteerd'}.`,
        contacts_sync_failed: `De facturatiecontacten konden niet worden gesynchroniseerd met Bocount. Geen ${this.documentType === 1 ? 'facturatie' : 'creditering'} mogelijk.`,
        no_general_ledger_account: 'Het product gekoppeld aan deze kost heeft geen grootboekrekening. Neem contact op met IT.',
        role_split_share: 'De verdeling per kost bij gesplitste facturatie (eigenaar - koper - huurder) is niet gelijk aan 100%.',
        general_exception: `Er ging iets mis bij het ${this.documentType === 1 ? 'factureren' : 'crediteren'} van de kosten.`
      }
    }
  }
}
</script>
