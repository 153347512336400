<template>
  <div v-if="messages.length" class="tw-border-t tw-mt-2">
    <details>
      <summary class="tw-font-bold tw-mt-2">Verzonden e-mails</summary>
      <p v-for="message in messages" :key="message.id" class="tw-mt-2 tw-mb-0 hover:">
        <span :class="[
          'fa fa-circle tw-mr-1',
          messageIsSent(message)
            ? 'tw-text-green-500'
            : 'tw-text-error'
          ]"
        />
        {{ message.message.to.display_name }}
        <button
          type="button"
          class="fa fa-eye tw-ml-1"
          @click="showContent(message)"
        />
      </p>
    </details>
    <BaseModal ref="content" title="Inhoud automatische e-mail">
      <div v-html="emailContent" />
    </BaseModal>
  </div>
</template>

<script>
export default {
  name: 'EntityInvoiceCommunication',
  props: {
    messages: {
      type: Array
    }
  },
  data () {
    return {
      emailContent: null
    }
  },
  methods: {
    messageIsSent (message) {
      return message?.message?.status === 1
    },
    showContent (message) {
      this.emailContent = message.message.message
      this.$refs.content.show()
    }
  }
}
</script>
