<template>
  <BaseModal
    title="Factuur crediteren"
    ref="modal"
  >
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="createCreditNoteForm"
      @submit="submit"
    >
      <FormulateInput
        type="textarea"
        name="credit_note_reason"
        label="Reden voor creditnota"
        placeholder="Reden voor creditnota"
        validation="required"
        :input-class="['tw-h-24']"
        outer-class="tw-my-0"
      />
      <FormulateErrors class="tw-text-right" />

      <div class="tw-flex tw-justify-end">
        <FormulateInput type="submit" :disabled="isLoading" outer-class="tw-mt-2">
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-file'
            ]"
          />
          Crediteren
        </FormulateInput>
      </div>
      <div class="tw-flex tw-justify-end">
        <EntityInvoiceProgress
          :invoicing="isLoading"
          :document-type="DOCUMENT_TYPE"
          :progress="progress"
        />
      </div>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { createCreditNoteForInvoice } from '@/services/invoices'
import { getJobStatus } from '@/services/apiService'

import { poll } from '@/utils/helpers'
import { errorModal, successModal, warningModal } from '@/modalMessages'

import EntityInvoiceProgress from '@/components/properties/EntityInvoiceProgress'

export default {
  name: 'EntityCreditNoteModal',
  components: {
    EntityInvoiceProgress
  },
  constants: {
    DOCUMENT_TYPE: 2 // credit note
  },
  data () {
    return {
      values: {},
      invoiceId: null,
      progress: null
    }
  },
  methods: {
    show (invoiceId) {
      this.invoiceId = invoiceId
      this.$refs.modal.show()
    },
    setProgress (progress) {
      this.progress = progress.meta
    },
    async submit (data) {
      try {
        const response = await createCreditNoteForInvoice(this.invoiceId, data)
        const jobResponse = await poll(response.data.job_id, getJobStatus, 1000, null, this.setProgress)
        this.invoiceId = null
        successModal('Factuur werd succesvol gecrediteerd.')
        this.$refs.modal.hide()
        this.$emit('creditingDone')
        return [response, jobResponse]
      } catch (error) {
        if (error?.response?.data?.code === 'CREDIT_NOTE_COST_CREATION_BLOCKED') {
          return warningModal('Creditering in deze fase van het dossier is enkel mogelijk door superusers. Gelieve contact op te nemen met de IT-helpdesk.')
        }
        console.error(error)
        errorModal('Er ging iets mis bij het crediteren.')
      }
    }
  }
}
</script>
