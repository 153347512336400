import { render, staticRenderFns } from "./PortfolioInvoice.vue?vue&type=template&id=59227225&"
import script from "./PortfolioInvoice.vue?vue&type=script&lang=js&"
export * from "./PortfolioInvoice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports